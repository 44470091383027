import React from "react";
import Img from "gatsby-image";
import { OutboundLink } from "gatsby-plugin-google-analytics";

const FeaturedItemCard = ({ image, title, subtitle, link }) => {
  return (
    <div className="max-w-xs bg-white shadow-lg rounded-lg overflow-hidden mx-auto my-2">
      <Img
        fluid={image.childImageSharp.fluid}
        alt={title}
        className="w-full h-56 "
      />

      <div className="py-5 text-center">
        <p className="block text-2xl text-gray-800 font-bold">{title}</p>
        <span className="text-sm text-gray-700">{subtitle}</span>
        <div className="mt-2">
          {link && (
            <OutboundLink href={link}>
              <button className="bg-gray-800 text-xs text-white px-2 py-1 font-semibold rounded uppercase hover:bg-gray-700">
                Order Now
              </button>
            </OutboundLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeaturedItemCard;
