import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Sugar from "sugar";
import { getSlug } from "../../utils/getSlug";

const PostCard = ({ date, title, content, image }) => {
  return (
    <div className="w-full md:max-w-xs lg:max-w-md mx-4 bg-white overflow-hidden shadow-md rounded-lg my-2">
      <Link to={`/post/${getSlug(title)}`}>
        <Img
          fluid={image.childImageSharp.fluid}
          alt={title}
          className="w-full h-64 "
        />
      </Link>

      <div className="p-6">
        <span className="text-blue-600 text-xs font-medium uppercase">
          {Sugar.Date.medium(Sugar.Date.create(date))}
        </span>
        <div>
          <Link
            to={`/post/${getSlug(title)}`}
            className="block text-gray-800 font-semibold text-2xl mt-2 hover:text-gray-600 hover:underline"
          >
            {title}
          </Link>
          <div
            className="text-sm text-gray-600 mt-2"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default PostCard;
