import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import phobowl from "../images/header.jpg";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FeaturedItemCard from "../components/FeaturedItemCard/FeaturedItemCard";
import PostCard from "../components/PostCard/PostCard";
import WaveBottom from "../components/Wave/WaveBottom";
import { OutboundLink } from "gatsby-plugin-google-analytics";

function IndexPage() {
  const {
    allMarkdownRemark: { edges: items },
    markdownRemark: { frontmatter: homepageInfo },
  } = useStaticQuery(graphql`
    query MyQuery {
      markdownRemark(frontmatter: { pageName: { eq: "homeindex" } }) {
        frontmatter {
          title
          tagline
          storeInfo {
            address
            email
            phone
            info
            hoursText2
            hoursText1
            hoursHeading2
            hoursHeading1
          }
          menu {
            menu1 {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            menu2 {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            menu3 {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            menu4 {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          buttonText
          buttonLink
          button2Text
          button2Link
          bottom {
            buttonLink
            buttonText
            subtitle
            tagline
          }
        }
      }
      allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
        edges {
          node {
            frontmatter {
              title
              templateKey
              date
              link
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            html
          }
        }
      }
    }
  `);

  const {
    title,
    tagline,
    storeInfo,
    menu,
    buttonLink,
    buttonText,
    button2Link,
    button2Text,
    bottom,
  } = homepageInfo;

  const featuredItems = items.filter(
    (item) => item.node.frontmatter.templateKey === "featuredItem"
  );
  const posts = items
    .filter((item) => item.node.frontmatter.templateKey === "post")
    .slice(0, 4);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <Layout>
      <SEO
        keywords={[
          `pho`,
          `bun bo hue`,
          `vietnamese`,
          `food`,
          `beef`,
          `noodle`,
          `soup`,
          `grill`,
          `vermicelli`,
        ]}
        title="Home"
      />

      <div
        className="h-96 w-full bg-cover bg-center"
        style={{
          backgroundImage: `url(${phobowl})`,
        }}
      >
        <div
          className="flex items-center justify-center h-full w-full bg-gray-900 bg-opacity-50"
          id="home"
        >
          <div className="flex flex-col w-full justify-center items-start text-center  mb-4 md:mb-4">
            <p className="uppercase tracking-loose w-full">{title}</p>
            <h1 className="my-4 text-5xl w-full font-bold leading-tight ">
              {tagline}
            </h1>

            <div className="w-full">
              <div className="mx-auto">
                <OutboundLink href={buttonLink}>
                  <button className="mx-2  hover:cursor-pointer bg-yellow-600 hover:bg-yellow-500 text-white font-bold rounded my-2 md:my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline  transition duration-300 ease-in-out z-10">
                    {buttonText}
                  </button>
                </OutboundLink>
                <OutboundLink href={button2Link}>
                  <button className="mx-2  hover:cursor-pointer  bg-yellow-700 hover:bg-yellow-500 text-white font-bold rounded my-2 md:my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline  transition duration-300 ease-in-out z-10">
                    {button2Text}
                  </button>
                </OutboundLink>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-white border-b py-12" id="info">
        <div className="container max-w-5xl mx-auto m-8">
          <h2 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Store Info
          </h2>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <p className="container mx-auto px-8 text-gray-800 mb-8">
            {storeInfo.info}
          </p>
          <div className="container flex flex-wrap justify-around mx-auto text-black px-4 ">
            <div className="w-full md:w-1/3 flex-col bg-yellow-600 rounded px-4 mx-4 mb-5 md:mb-0 shadow-lg">
              <div className="text-white uppercase text-xl font-bold text-center my-2 font-serif">
                Contact Info
              </div>
              <div className="w-full mb-4">
                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
              </div>
              <div className="flex items-center my-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-8 mr-2 stroke-current text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <p className="text-white text-sm">{storeInfo.phone}</p>
              </div>
              <div className="flex items-center my-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-8 mr-2 stroke-current text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <p className="text-white text-sm cursor-pointer">
                  <a
                    href="https://www.google.com/maps/place/Pho+Phi/@38.2481667,-85.6294945,15z/data=!4m12!1m6!3m5!1s0x0:0x89ccd43eec12f698!2sPho+Phi!8m2!3d38.2481667!4d-85.6294945!3m4!1s0x0:0x89ccd43eec12f698!8m2!3d38.2481667!4d-85.6294945"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {storeInfo.address}
                  </a>
                </p>
              </div>
              <div className="flex items-center my-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-8 mr-2 stroke-current text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                  />
                </svg>
                <p className="text-white text-sm">{storeInfo.email}</p>
              </div>
            </div>
            <div className="w-full md:w-1/3 flex-col bg-yellow-600 rounded px-4 mx-4 shadow-lg">
              <div className="text-white uppercase text-xl font-bold text-center my-2 font-serif">
                Store Hours
              </div>
              <div className="w-full mb-4">
                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
              </div>
              <div className="flex items-center my-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-8 mr-2 stroke-current text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div className="flex flex-col">
                  <p className="text-white text-sm font-semibold">
                    {storeInfo.hoursHeading1}
                  </p>
                  <p className="text-white text-sm">{storeInfo.hoursText1}</p>
                </div>
              </div>
              <div className="flex items-center my-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-8 mr-2 stroke-current text-white"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <div className="flex flex-col">
                  <p className="text-white text-sm font-semibold">
                    {storeInfo.hoursHeading2}
                  </p>
                  <p className="text-white text-sm">{storeInfo.hoursText2}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-800 border-b py-12" id="menu">
        <div className="container mx-auto flex flex-wrap pt-4 pb-12 px-0 md:px-8 lg:px-24 xl:px-64">
          <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-100">
            Menu
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
            <Img fluid={menu.menu1.childImageSharp.fluid} alt="menu1" />
          </div>
          <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
            <Img fluid={menu.menu2.childImageSharp.fluid} alt="menu2" />
          </div>
          <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
            <Img fluid={menu.menu3.childImageSharp.fluid} alt="menu3" />
          </div>
          <div className="w-full md:w-1/2 p-6 flex flex-col flex-grow flex-shrink">
            <Img fluid={menu.menu4.childImageSharp.fluid} alt="menu4" />
          </div>
        </div>
      </section>

      <section className="bg-gray-100 py-12" id="featuredItems">
        <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
          <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-800">
            Featured Items
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={responsive}
            ssr={false} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={5000}
            keyBoardControl={true}
            transitionDuration={500}
            containerClass="carousel-container"
            removeArrowOnDeviceType={["tablet", "mobile"]}
            // deviceType={this.props.deviceType}
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {featuredItems.map(({ node: { frontmatter } }) => (
              <FeaturedItemCard
                key={frontmatter.title}
                title={frontmatter.title}
                image={frontmatter.featuredImage}
                subtitle={frontmatter.subtitle}
                link={frontmatter.link}
              />
            ))}
          </Carousel>
        </div>
      </section>

      <section className="bg-gray-600 py-12" id="posts">
        <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800">
          <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-gray-100">
            Posts
          </h1>
          <div className="w-full mb-4">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>

          <div className="flex justify-around flex-wrap">
            {posts.map(({ node: { frontmatter, html } }) => {
              return (
                <PostCard
                  key={frontmatter.title}
                  date={frontmatter.date}
                  title={frontmatter.title}
                  image={frontmatter.featuredImage}
                  content={html}
                />
              );
            })}
          </div>
          <div className="w-full mb-4 pt-6">
            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
          <div className="text-center">
            <Link
              to="/posts"
              className="text-blue-200 hover:text-gray-300 transition duration-100"
            >
              View all posts
            </Link>
          </div>
        </div>
      </section>

      <WaveBottom color="#4B5563" />

      <section className="container mx-auto text-center py-6 mb-12">
        <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-white">
          {bottom.tagline}
        </h1>
        <div className="w-full mb-4">
          <div className="h-1 mx-auto bg-white w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
        </div>
        <h3 className="my-4 text-3xl leading-tight">{bottom.subtitle}</h3>

        <OutboundLink href={bottom.buttonLink}>
          <button className="mx-auto lg:mx-0 hover:underline bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg focus:outline-none focus:shadow-outline transform transition hover:scale-105 duration-300 ease-in-out">
            {bottom.buttonText}
          </button>
        </OutboundLink>
      </section>
    </Layout>
  );
}

export default IndexPage;
